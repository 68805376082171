import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import VideoJS from './VideoJS';
import Logo from './img/logo192.png'
import Chat from './Chat';
import SignIn from './SignIn';
import { auth } from '../firebase.js'
import { useAuthState } from 'react-firebase-hooks/auth'
import SignOut from './SignOut';
import Limpio from './Limpio'
import Shared from './Shared'
import Container from '@material-ui/core/Container';
import { Grid, Typography, Paper } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import Info from './Info'
import ListSubheader from '@material-ui/core/ListSubheader';
import Menu from './Menu'
const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: '#1f3089',
    },
    background: {
      default: '#b5c1f5',
      paper: '#b5c1f5',
    },
  },
});
const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#1f3089',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  
  content: {
    
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));



export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [user] = useAuthState(auth)
  return (
    <ThemeProvider theme={darkTheme}>
      <Paper style={{ height: "100%"}} className={classes.root2}>
    <div className={classes.root}>
  
      <CssBaseline />
      <AppBar 
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          
          <Typography variant="h6" style={{ fontWeight: "800", flexGrow: 1, textAlign: "center", alignItems: "center", display: 'flex', justifyContent: "center" }} className={classes.title}>
          
          {process.env.REACT_APP_TITLE_APP}
          </Typography>
            <Info />
            <Shared />
          {user ? <SignOut /> : <Limpio/>}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
        <Typography variant="h7" style={{ fontWeight: "800", flexGrow: 1, textAlign: "center", alignItems: "center", display: 'flex', justifyContent: "center" }}>
          <img src={Logo} alt="Logo"/>
          {process.env.REACT_APP_TITLE_APP}
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <Menu />
        <Divider />
                  <ListSubheader>{process.env.REACT_APP_DERECHOS_NAME}©</ListSubheader>
      </Drawer>
      
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <div className={classes.root} >
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} md={8} lg={8}>
          <VideoJS/>
          
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
        <React.Fragment>
      <CssBaseline />
      <Container> 
      {user ? <Chat /> : <SignIn/>}
      </Container>
    </React.Fragment>
        </Grid>
        
      </Grid>
      
    </div>
      </main>
    </div>
    </Paper>
    </ThemeProvider>
  );
}
