import firebase from 'firebase'

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCvMGtTjHfReXHQsoMZALz7uJ1XrmGgQ6Y",
    authDomain: "rpcvisiontv.firebaseapp.com",
    projectId: "rpcvisiontv",
    storageBucket: "rpcvisiontv.appspot.com",
    messagingSenderId: "412032734083",
    appId: "1:412032734083:web:a81a1ed5cf3a631fb15cb6",
    measurementId: "G-8ZEJGK5GMF"
})

const db = firebaseApp.firestore()

const auth = firebase.auth()

export { db, auth }